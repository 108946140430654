<template>
  <div class="rehabilitation">
    <router-link to="/"><img src="@/assets/img/goback_icon.svg" class="gobackBtn"></router-link>
    <div class="rehabilitation_list">
      <div v-for="(item,i) in reList" :key="i" :class="item.class" @click="jump(item.path)">
        <img :src="item.img">
        <h3>{{item.title}}</h3>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Rehabilitation",
  data() {
    return {
      reList: [
        {
          img: require('@/assets/img/appraisal_icon.svg'),
          title: '体适能运动测评',
          path: 'appraisal',
          class: 'appraisal bgBlue'
        },
        {
          img: require('@/assets/img/gaitrace_icon.svg'),
          title: '步态功能评估与训练',
          path: 'gaitrace',
          class: 'gaitrace bgBlue'
        },
        {
          img: require('@/assets/img/vr_icon.svg'),
          title: 'VR认知功能康复',
          path: 'vr',
          class: 'vr bgGreen'
        },
        {
          img: require('@/assets/img/car_icon.svg'),
          title: '下肢运动康复器',
          path: 'car',
          class: 'car bgGreen'
        }
      ]
    }
  },
  methods: {
    jump(path) {
      this.$router.push({
        path: '/rehabilitationDetails',
        query: {
          category: path
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rehabilitation {
  padding: 0 0.16rem;
  .gobackBtn {
    margin: 0.16rem 0;
    display: block;
    width: 0.31rem;
    height: 0.31rem;
  }
  .rehabilitation_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      width: 1.68rem;
      height: 2.17rem;
      margin-top: 0.08rem;
      border-radius: 0.1rem;
      img {
        display: block;
      }
      h3 {
        font-size: 0.16rem;
        color: #fff;
        text-align: center;
      }
    }
    .appraisal {
      img {
        margin: 0.6rem auto 0.2rem;
      }
    }
    .gaitrace {
      img {
        margin: 0.51rem auto 0.21rem;
      }
    }
    .vr {
      img {
        margin: 0.6rem auto 0.17rem;
      }
    }
    .car {
      img {
        margin: 0.64rem auto 0.24rem;
      }
    }
    .bgBlue {
      background: #88a9e5;
    }
    .bgGreen {
      background: #73d4cb;
    }
  }
}
</style>